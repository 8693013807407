var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('ui-list',{attrs:{"heading":_vm.heading,"headings":[_vm.$t('fields.name'), _vm.$tc('models.role', 1), ''],"items":_vm.users,"sort":[
      { prop: 'lastName', type: false },
      { prop: 'role', prop2: 'name', type: 'level2' }
    ]},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":`${
            props.item.firstName
              ? props.item.firstName
              : _vm.$t('misc.not-registered')
          } ${props.item.lastName ? props.item.lastName : ''}`,"info":props.item.email}}),_c('ui-list-data',{attrs:{"type":"tag","text":`${_vm.$t('roles.' + props.item.role.name)}`}}),_c('ui-list-data',{attrs:{"small":""}},[_c('ui-link',{attrs:{"type":"danger","size":"small","uppercase":""},on:{"click":function($event){return _vm.showDeleteModal(true, props.item)}}},[_vm._v(_vm._s(_vm.$t('actions.remove')))])],1)]}}])}),_c('ui-modal',{attrs:{"show":_vm.showDelete,"text":_vm.$t('actions.confirm_delete', {
        model: _vm.$tc('models.user').toLowerCase()
      }),"info":_vm.$t('info.delete_company_user'),"confirmText":_vm.$t('actions.delete')},on:{"confirm":function($event){return _vm.removeUser()},"cancel":function($event){return _vm.showDeleteModal(false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }